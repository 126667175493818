import styled from 'styled-components'

export const Container = styled.div`

   max-height:100%;
   padding-bottom: 100px;
   justify-content: center;
   display: flex;
   background: #F2F3F8;

   & > div{
      width: 90%;
      
      &:first-child{
         @media (min-width: 320px) and (max-width: 834px){
            display: none;
         }
      }

      & > div{      
         &:first-child{
            position:relative;
            z-index: 2; 
         }
      }
   }

   @media (min-width: 320px) and (max-width: 840px) {
      flex-direction: column;
      align-items: center;
   }
`
export const DivCard = styled.div`

   background: linear-gradient(180.05deg, #FFFFFF 0.03%, #F2F3F8 99.97%);
   box-shadow: 1px 12px 20px rgba(0, 0, 0, 0.2);
   border-radius: 8px;
   text-align: center;
   margin: 10px;
   display: flex;
   flex-direction: column;
   align-items: center;

   &:hover{

      & > .symbol {
         background: #25406D;
      }
         a{
            background: #08C1C1;
         }
      } 

      @media (min-width: 375px) and (max-width: 414px){
         width: 330px;
      }

      @media (min-width: 320px) and (max-width: 374px){
         width: 300px;
      }
`

export const ImageDiv = styled.div`
   padding-top: 50px;
   padding-bottom: 50px;
   
   & > img{
      max-height: 80px;
   }

`
export const SymbolDiv = styled.div`
   background: #08C1C1;
   border: 2px solid #FFFFFF;
   box-sizing: border-box;
   box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
   border-radius: 100%;

   & > img{
      height: 100px;
      padding: 10px;
      margin: 5px;
   }

`

export const TextDiv = styled.div`
   display: flex;
   flex-direction: column;
   font-size: 20px;
   max-width:300px;
   height: 200px;
   padding: 40px 10px;

   & > .titulo{
      color: #08C1C1;
      font-weight: bold;
      padding-bottom: 20px;
   }

   & > .texto{
      color: #1E3760;
   }

   @media(min-width: 835px) and (max-width: 1024px){
      height: 300px;
   }

`

export const ButtonDiv = styled.div`

   padding-top: 20px;
   display: flex;
   align-self: center;
   margin-bottom: 50px;

   & > a {
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      border: 1px solid #1E3760;;
      padding: 10px 50px;
      border-radius: 18px;
      color: #1E3760;
   }

`
export const Img = styled.img`

   width: 140px;
   z-index: 1;
   cursor: pointer;

   &:hover{
      color: #1E3760;
      fill: #1E3760;
   }

   @media(min-width: 835px) and (max-width: 1024px){
      width: 110px;
   }

`

export const Left = styled.div`

   padding-right: 90px;
   z-index: 1;

`

export const Right = styled.div`

   padding-left: 90px;
   z-index: 1;

`
