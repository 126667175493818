import styled from 'styled-components'

export const BackgroundContainer = styled.div`

    width: 100%;
    background-color: #F2F3F8;
    box-sizing: border-box;
    padding-top: 50px;
    padding-bottom: 50px;
`

export const ContentContainer = styled.div`

    position: relative;
    width: 100%; 
    /* height: 1080px; */
    display: flex; 
    flex-direction: column;

    & > div > img{
        max-width: 470px;
    }

    @media (min-width: 320px) and (max-width: 414px){
        height: auto;

        & > div > img{
            max-width: 300px;
        }
    }

`

export const TitleContainer = styled.div`

    display: flex; 
    justify-content: space-around; 
    width: 100%;

    & > div > img{
        max-width: 470px;
    }

    @media (min-width: 768px) and (max-width: 835px){
        & > div > img{
            max-width: 330px;
        }
    }

    @media (min-width: 320px) and (max-width: 414px){
        & > div > img{
            display: none;
        }
    }
`

export const TitleDiv = styled.div`

    display: flex; 
    justify-content: center; 
    width: 100%;
    flex-direction: column;
    align-items: center;

    & > span{
        
        text-align: center;
        text-transform: uppercase; 
        font-size: 45px; 
        color: #08C1C1;
        max-width: 800px;
    }

    @media (min-width: 320px) and (max-width: 1024px){
        & > span{
        font-size: 35px;
        }
    }

    @media (min-width: 320px) and (max-width: 414px){
        margin-right: unset;
    } 
`

export const ItemsContainer = styled.div`

    display: flex;
    margin-top: 200px;
    width: 100%;

    @media (min-width: 320px) and (max-width: 414px){
        flex-direction: column;
    }

`

export const LeftDiv= styled.div`

    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 50px;

    @media (min-width: 320px) and (max-width: 414px){
        width: 100%;
        margin-right: unset;
    }

`

export const RightDiv = styled.div`

    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 50px;

    @media (min-width: 320px) and (max-width: 414px){
        width: 100%;
        margin-bottom: 100px;
        margin-left: unset;
    }
`

export const Item = styled.div`

    display: flex; 
    flex-direction: ${props => props.direction}; 
    justify-content: flex-end;
    align-items: center;
    min-height: 88px;
    margin-bottom: 100px;
`

export const ItemText = styled.span`

    font-size: 18px;
    color: #1E3760;
    text-align: ${props => props.align? props.align : 'unset'};
    max-width: 400px;
    margin-right: ${props => props.right? props.right +'px' : 'unset'};
    margin-left: ${props => props.left? props.left +'px' : 'unset'};
`

export const ItemImage = styled.img`

    box-shadow: 1px 12px 20px rgba(0, 0, 0, 0.2), 0px -4px 10px #FFFFFF, inset 1px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    height: 75px;
`
