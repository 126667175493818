import React from 'react'
import { BackgroundContainer, ContentContainer, TitleContainer, TitleDiv, ItemsContainer, LeftDiv, RightDiv, Item, ItemText, ItemImage } from './styles'

function Advantages ({ leftItems, rightItems }) {

	return(
		<BackgroundContainer>
				
			<ContentContainer>
					
				<TitleContainer>
						
					<TitleDiv>
						<span> <b> Uma plataforma. diversas facilidades para você </b> </span>
					</TitleDiv>

					<span></span>

				</TitleContainer>

				<ItemsContainer>

						<LeftDiv>

							{
								leftItems.map((value, index) => {
									return(
										<Item key={index} direction={'row'} >
											<ItemText key={index} align={'right'} right={25}>
												{value.text}
											</ItemText>
											<ItemImage src={value.image.src} alt={value.image.alt} />
										</Item>
									)
								})
							}

						</LeftDiv>

						<RightDiv>

								{
									rightItems.map((value, index) => {
										return(
											<Item key={index} direction={'row-reverse'} >
												<ItemText key={index} left={25}>
														{value.text}
												</ItemText>
												<ItemImage src={value.image.src} alt={value.image.alt}/>
											</Item>
										)
									})
								}
								
						</RightDiv>

				</ItemsContainer>

			</ContentContainer>

		</BackgroundContainer>
	)

}

export default Advantages