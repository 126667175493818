import React, { useState } from 'react';
import ItemsCarousel from 'react-items-carousel'
import { Container, Img, Right, Left, DivCard, ImageDiv, SymbolDiv, TextDiv, ButtonDiv } from './styles'
import Cards from '../../components/cards'
import { Link } from 'gatsby'

import ArrowRight from '../../images/ArrowCardRight.svg'
import ArrowLeft from '../../images/ArrowCardLeft.svg'
import BlueArrow from '../../images/ArrowBlue.svg'

import ManagerLogo from '../../images/imagesProducts/wowManager.png'
import ManagerSymbol from '../../images/imagesProducts/Manager.png'
// import CredLogo from '../../images/imagesProducts/wowCred.png'
// import CredSymbol from '../../images/imagesProducts/Cred.png'
import CardLogo from '../../images/imagesProducts/wowCard.png'
import CardSymbol from '../../images/imagesProducts/Card.png'
// import TrustLogo from '../../images/imagesProducts/wowTrust.png'
// import TrustSymbol from '../../images/imagesProducts/Trust.png'
import PayLogo from '../../images/imagesProducts/wowPay.png'
import PaySymbol from '../../images/imagesProducts/Pay.png'
// import ClubLogo from '../../images/imagesProducts/wowClub.png'
// import ClubSymbol from '../../images/imagesProducts/Club.svg'


export default () => {

const [activeItemIndex, setActiveItemIndex] = useState(0);
const chevronWidth = 100;
const [ onHover, setOnHover ] = useState();

   return (

   <Container>
      <ItemsCarousel
         requestToChangeActive={setActiveItemIndex}
         activeItemIndex={activeItemIndex}
         gutter={0}
         numberOfCards={4}
         leftChevron={<Left onMouseOver={() => {setOnHover(true)}} 
            onFocus={() => {setOnHover(true)}} 
            onMouseOut={() => {setOnHover(false)}} 
            onBlur={onHover} 
            onfocusout={() => {setOnHover(false)}} ><Img src={onHover? BlueArrow : ArrowLeft} alt='LeftArrow'/></Left>}
         rightChevron={<Right onMouseOver={() => {setOnHover(true)}} 
            onFocus={() => {setOnHover(true)}} 
            onMouseOut={() => {setOnHover(false)}}
            onBlur={onHover} 
            onfocusout={() => {setOnHover(false)}} ><Img src={onHover? BlueArrow : ArrowRight} alt='RightArrow'/></Right>}
         outsideChevron={false}
         chevronWidth={chevronWidth}
         infiniteLoop={true}
      >
         <DivCard>
            <ImageDiv>
               <img src={ManagerLogo} alt='Logo'></img>
            </ImageDiv>
            <SymbolDiv className='symbol'>
               <img src={ManagerSymbol} alt='Product'></img>
            </SymbolDiv>
            <TextDiv>
               <span className='titulo'>Wow Manager</span>
               <span className='texto'>Gerenciar é fácil e rápido por aqui. Emita e pague boletos e transferências, únicos e em lotes também.</span>
            </TextDiv>
            <ButtonDiv><Link to='/manager'>Saiba Mais</Link></ButtonDiv>
         </DivCard>

         <DivCard>
            <ImageDiv>
               <img src={PayLogo} alt='Logo'></img>
            </ImageDiv>
            <SymbolDiv className='symbol'>
               <img src={PaySymbol} alt='Product'></img>
            </SymbolDiv>
            <TextDiv>
               <span className='titulo'>Wow Pay</span>
               <span className='texto'>Vendas, presenciais ou não, gestão de fluxo, simulação de preços. Sua empresa prosperando com a Wow.</span>
            </TextDiv>
            <ButtonDiv><Link to='/pay'>Saiba Mais</Link></ButtonDiv>
         </DivCard>
         
         <DivCard>
            <ImageDiv>
               <img src={CardLogo} alt='Logo'></img>
            </ImageDiv>
            <SymbolDiv className='symbol'>
               <img src={CardSymbol} alt='Product'></img>
            </SymbolDiv>
            <TextDiv>
               <span className='titulo'>Wow Card</span>
               <span className='texto'>Esqueça o medo da fatura do cartão. Cartão de crédito pré-pago e internacional para você não perder o controle.</span>
            </TextDiv>
            <ButtonDiv><Link to='/card'>Saiba Mais</Link></ButtonDiv>
         </DivCard>

         {/* <DivCard>
            <ImageDiv>
               <img src={CredLogo} alt='Logo'></img>
            </ImageDiv>
            <SymbolDiv className='symbol'>
               <img src={CredSymbol} alt='Product'></img>
            </SymbolDiv>
            <TextDiv>
               <span className='titulo'>Wow Cred</span>
               <span className='texto'>Aquela ajudinha extra em forma de crédito para você. Crédito com taxas especiais para surpreender você e sua empresa.</span>
            </TextDiv>
            <ButtonDiv><Link to='/cred'>Saiba Mais</Link></ButtonDiv>
         </DivCard> */}

         {/* <DivCard>
            <ImageDiv>
               <img src={TrustLogo} alt='Logo'></img>
            </ImageDiv>
            <SymbolDiv className='symbol'>
               <img src={TrustSymbol} alt='Product'></img>
            </SymbolDiv>
            <TextDiv>
               <span className='titulo'>Wow Trust</span>
               <span className='texto'>Sem fraudes e erros. Conciliação completa e simplificada de todas as maquininhas do seu negócio.</span>
            </TextDiv>
            <ButtonDiv><Link to='/trust'>Saiba Mais</Link></ButtonDiv>
         </DivCard> */}

         {/* <DivCard>
            <ImageDiv>
               <img src={ClubLogo} alt='Logo'></img>
            </ImageDiv>
            <SymbolDiv className='symbol'>
               <img src={ClubSymbol} alt='Product'></img>
            </SymbolDiv>
            <TextDiv>
               <span className='titulo'>Wow Club</span>
               <span className='texto'>Crie promoções, descontos e ofereça cashback para fidelizar seus clientes.</span>
            </TextDiv>
            <ButtonDiv><Link to='/club'>Saiba Mais</Link></ButtonDiv>
         </DivCard> */}

      </ItemsCarousel>
      
      <Cards />
   </Container>
   );
}