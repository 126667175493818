import React from 'react'
import { Container, DivCard, ImageDiv, SymbolDiv, TextDiv, ButtonDiv } from './styles'
import { Link } from 'gatsby'

import ManagerLogo from '../../images/imagesProducts/wowManager.png'
import ManagerSymbol from '../../images/imagesProducts/Manager.png'
// import CredLogo from '../../images/imagesProducts/wowCred.png'
// import CredSymbol from '../../images/imagesProducts/Cred.png'
import CardLogo from '../../images/imagesProducts/wowCard.png'
import CardSymbol from '../../images/imagesProducts/Card.png'
// import TrustLogo from '../../images/imagesProducts/wowTrust.png'
// import TrustSymbol from '../../images/imagesProducts/Trust.png'
import PayLogo from '../../images/imagesProducts/wowPay.png'
import PaySymbol from '../../images/imagesProducts/Pay.png'
// import ClubLogo from '../../images/imagesProducts/wowClub.svg'
// import ClubSymbol from '../../images/imagesProducts/Club.svg'



export default () => {


   return(

      <Container>
         <DivCard>
            <ImageDiv>
               <img src={ManagerLogo} alt='Logo'></img>
            </ImageDiv>
            <SymbolDiv className='symbol'>
               <img src={ManagerSymbol} alt='Product'></img>
            </SymbolDiv>
            <TextDiv>
               <span className='titulo'>Wow Manager</span>
               <span className='texto'>Gerenciar é fácil e rápido por aqui. Emita e pague boletos e transferências, únicos e em lotes também.</span>
            </TextDiv>
            <ButtonDiv><Link to='/manager'>Saiba Mais</Link></ButtonDiv>
         </DivCard>
         
         <DivCard>
            <ImageDiv>
               <img src={PayLogo} alt='Logo'></img>
            </ImageDiv>
            <SymbolDiv className='symbol'>
               <img src={PaySymbol} alt='Product'></img>
            </SymbolDiv>
            <TextDiv>
               <span className='titulo'>Wow Pay</span>
               <span className='texto'>Vendas, presenciais ou não, gestão de fluxo, simulação de preços. Sua empresa prosperando com a Wow.</span>
            </TextDiv>
            <ButtonDiv><Link to='/pay'>Saiba Mais</Link></ButtonDiv>
         </DivCard>

         <DivCard>
            <ImageDiv>
               <img src={CardLogo} alt='Logo'></img>
            </ImageDiv>
            <SymbolDiv className='symbol'>
               <img src={CardSymbol} alt='Product'></img>
            </SymbolDiv>
            <TextDiv>
               <span className='titulo'>Wow Card</span>
               <span className='texto'>Esqueça o medo da fatura do cartão. Cartão de crédito pré-pago e internacional para você não perder o controle.</span>
            </TextDiv>
            <ButtonDiv><Link to='/card'>Saiba Mais</Link></ButtonDiv>
         </DivCard>

         {/* <DivCard>
            <ImageDiv>
               <img src={CredLogo} alt='Logo'></img>
            </ImageDiv>
            <SymbolDiv className='symbol'>
               <img src={CredSymbol} alt='Product'></img>
            </SymbolDiv>
            <TextDiv>
               <span className='titulo'>Wow Cred</span>
               <span className='texto'>Aquela ajudinha extra em forma de crédito para você. Crédito com taxas especiais para surpreender você e sua empresa.</span>
            </TextDiv>
            <ButtonDiv><Link to='/cred'>Saiba Mais</Link></ButtonDiv>
         </DivCard> */}

         {/* <DivCard>
            <ImageDiv>
               <img src={TrustLogo} alt='Logo'></img>
            </ImageDiv>
            <SymbolDiv className='symbol'>
               <img src={TrustSymbol} alt='Product'></img>
            </SymbolDiv>
            <TextDiv>
               <span className='titulo'>Wow Trust</span>
               <span className='texto'>Sem fraudes e erros. Conciliação completa e simplificada de todas as maquininhas do seu negócio.</span>
            </TextDiv>
            <ButtonDiv><Link to='/trust'>Saiba Mais</Link></ButtonDiv>
         </DivCard> */}

         {/* <DivCard>
            <ImageDiv>
               <img src={ClubLogo} alt='Logo'></img>
            </ImageDiv>
            <SymbolDiv className='symbol'>
               <img src={ClubSymbol} alt='Product'></img>
            </SymbolDiv>
            <TextDiv>
               <span className='titulo'>Wow Club</span>
               <span className='texto'>Crie promoções, descontos e ofereça cashback para fidelizar seus clientes.</span>
            </TextDiv>
            <ButtonDiv><Link to='/club'>Saiba Mais</Link></ButtonDiv>
         </DivCard> */}

         {/* <div>
         {data.site.siteMetadata.products.map(produtos => (
         
            <DivCard>
               <ImageDiv>
                  <img src={produtos.img} alt='Logo'></img>
               </ImageDiv>
               <SymbolDiv>
                  <img src={produtos.symbol} alt='Product'></img>
               </SymbolDiv>
               <TextDiv>
                  <span className='titulo'>{produtos.titulo}</span>
                  <span className='texto'>{produtos.texto}</span>
               </TextDiv>
               <ButtonDiv>
                  <Link>Saiba Mais</Link>
               </ButtonDiv>
            </DivCard>
         
         ))}
         </div> */}
      
      </Container>

   )
}