import styled from 'styled-components'
import BlueBlock from '../../images/blueBlock.png'
import Wvector from '../../images/Wvector.png'
import WowManagerWeb from '../../images/imagesHome/wowManagerIpad.svg'
import WowManagerWebStatic from '../../images/imagesHome/IpadWow.png'


export const DivCriaConta = styled.div`

   width:100%;
   position: relative;
   z-index: 2;
   background-color: #F2F3F8;

   & > .wow-block-top{
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-top: 10px;
      margin-bottom: 50px;

      & > div > img{
         max-height: 160px;
      }

      @media (min-width: 320px) and (max-width: 414px){
         & > div > img{
            height: 80px;
         }
      }

   }

   & > .wow-block-bot{
      display: flex;
      width:100%;
      padding-bottom: 10px;
      margin-top: 50px;

      & > div > img{
         max-height: 160px;
      }

      @media (min-width: 320px) and (max-width: 414px){
         & > div > img{
            height: 80px;
         }
      }
   }

`

export const InfoCriaConta = styled.div`

   display: flex;
   flex-direction: row;
   justify-content: center;

   & > .text-left{

      max-width: 415px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > div {
         margin-bottom: 30px;
      }

      & > .cc-titulo{
         font-size: 48px;
         line-height: 56px;
         text-align: right;
         display: flex;
         flex-direction: column;
         color: #08C1C1;

      }

      &  > .cc-subtitulo{
         font-size: 24px;
         line-height: 28px;
         text-align: right;
         color: #1E3760;
      }

      &  > .cc-texto{
         font-size: 18px;
         line-height: 28px;
         text-align: right;
         display: flex;
         flex-direction: column;
         color: #25406D;
      }

      @media (min-width: 320px) and (max-width: 414px){
         max-width: 300px;
      }

   }

   @media (max-width : 1024px){
      flex-direction: column;
      align-items: center;
      
   }

`

export const BeneficiosDiv = styled.div`

   max-width: 415px;
   display: flex;
   align-self: center;

   & > div {

      & > ul{ 
         
         list-style-image: url(${BlueBlock});

         &  > li{
            font-size: 18px;
            line-height: 38px;
            color: #1E3760;
         }
      }
   
      &  > span {
         font-weight: 500;
         font-size: 24px;
         line-height: 28px;
         letter-spacing: 0.25px;
         color: #1E3760;
      }
   }

   @media (min-width: 320px) and (max-width: 767px){
      max-width: 300px;
   }

   @media (min-width: 320px) and (max-width: 1024px){
      margin-top: 30px;   
   }


`

export const IpadDiv = styled.div`

   & > div{
      
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      & > div {
         display:flex;
         align-items: center;
         justify-content: center;
      }

      &  >.cria-btn{

         position: absolute;
         z-index: 2;
         margin-bottom: 10px;

         @media (min-width: 320px) and (max-width: 840px){
            position: relative;
         }

         & > a{
            text-decoration: none;
            padding: 20px 15px 15px;
            background: #08C1C1;
            border-radius: 0 0 10px 10px;
            color: white;

            &:hover{
               background: #1E3760;
            }

         }
      }
   }

`

export const Ipad = styled.div`

   position: relative;
   z-index: 2;
   margin: 50px 50px 0px 50px;
   
   @media(min-width: 1024px) and (max-width: 1366px){
      margin: 0;
   }

   @media (min-width: 320px) and (max-width: 834px){
      display: none;
   }

   @media(min-width: 320px) and (max-width: 840px){
      margin: 0;
   }

   & > img {
      max-height: 600px;
      /* -webkit-box-shadow: 1px 4px 5px 11px rgba(0,0,0,0.07);
      -moz-box-shadow: 1px 4px 5px 11px rgba(0,0,0,0.07);
      box-shadow: 1px 4px 5px 11px rgba(0,0,0,0.07); */
      border-radius: 30px;

      @media (min-width: 375px) and (max-width: 414px){
         max-height: 480px;
      }

      @media(min-width: 320px) and (max-width: 374px){
         max-height: 450px;
      }

   }

`

export const WowManager = styled.div`

   z-index: 3;
   background-image: url(${WowManagerWeb});
   position:absolute;
   padding-bottom:20px;
   height: 460px;
   width: 380px;
   margin-top: 20px;
   background-attachment: fixed;
   background-position: top;
   background-repeat: no-repeat;
   background-size: auto;

   @media (min-width: 1440px) and (max-width: 1919px){
      margin-top: 25px;
      margin-bottom: 0;
      padding-bottom: 20px;
      padding-top: 0; 
   }

   @media (min-width: 1366px) and (max-width: 1439px){
      margin-top: 0;
      margin-bottom: 25px;
      padding-bottom: 0;
      padding-top: 20px;      
   }

   @media (min-width: 835px) and (max-width: 1024px){
      margin-top: 0;
      margin-bottom: 25px;
      padding-bottom: 0;
      padding-top: 20px;  
   }

   @media (min-width: 320px) and (max-width: 834px){
      background-image: url(${WowManagerWebStatic});
      height: 560px;
      width: 590px;
      background-attachment:unset;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: unset;
   }

   @media (min-width: 375px) and (max-width: 414px){
      max-height: 380px;
      max-width: 380px;
   }

   @media (min-width: 320px) and (max-width: 376px){
      max-height: 355px;
      max-width: 355px;
   }

   
`

export const DivCardsHome = styled.div`

   padding-top: 100px;
   background:#F2F3F8;

`

export const CardsTitulo = styled.div`

   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 100px;
   & > span{
      font-size: 48px;
      line-height: 114.69%;
      color: #08C1C1;
   }

   @media (min-width: 320px) and (max-width: 414px){

      & > span{
         font-size: 35px;

         &:first-child{
            width: 300px;
         }
      }
   }


`

export const DivPosBanner = styled.div`

   height: 100%;
   display: flex;
   background: #F2F3F8;

   @media (min-width : 320px) and (max-width : 840px){
      
      flex-direction: column;

      & > div{
         padding: 100px 0;
      }

   }

`

export const DivPos = styled.div`
   background: linear-gradient(180deg, #00879A 0%, #00BFBF 100%);
   width: 50%;
   display: flex;
   justify-content:center;
   padding: 100px 10px 100px 20px;

   & > .lista-pos{

      display: flex;
      align-items: center;

      & > ul{
         align-items: flex-end;
         display: flex;
         flex-direction: column;
         text-align: end;
         
         & > li{
            list-style: none;
            margin-top: 60px;
            align-items: center;
            display: flex;
            color:  #22EEE1;
            font-size: 18px;

            & > img{
               margin-left: 15px;
               height: 70px;
            }
         }
      }

      @media (min-width : 1024px) and (max-width : 1366px){
         & > ul > li{ 
            font-size: 15px;
            & > img{
               height: 55px;
            }
         }
      }

      @media (min-width : 320px) and (max-width : 414px){
         & > ul{ 
            padding-left: 0;
            & > li{ 
               font-size: 15px;
               margin-top: 30px;
               & > img{
                  height: 40px;
               }
            }
         }
      }

   }

   @media (min-width : 320px) and (max-width : 840px){      
      width:100%;
   }


   & > .image-pos{
      
      @media (min-width : 768px) and (max-width : 1366px){
         & > img{
            height: 700px;
         }
      }

      @media (min-width : 320px) and (max-width : 414px){
         & > img{
            height: 350px;
         }
      }

   }

`

export const DivInfo = styled.div`

   background: #F2F3F8;
   width: 50%;
   display: flex;
   flex-direction: column;
   align-self: center;
   padding-left: 5%;

   & > .info-titulo{
      color: #08C1C1;
      font-size: 48px;
      line-height: 114.69%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      @media (max-width : 1024px){
         font-size: 35px;
      }

   }

   & > .info-sub{
      color: #1E3760;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;

      @media (max-width : 1024px){
         font-size: 18px;
      }

   }

   & > .info-texto{
      display: flex;
      flex-direction: column;
      color: #1E3760;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 20px;
      width: 500px;

      & > span:last-child{
         margin-top: 10px;
      }
     
      @media (min-width : 320px) and (max-width : 414px){
         width: 300px;      
      }

      @media (min-width: 835px) and (max-width: 1024px){
         width: 390px;
      }

   }

   & > .info-btn{
      margin-top: 100px;
      margin-bottom: 100px;

      & > a{
         text-decoration: none;
         font-weight: 500;
         font-size: 14px;
         line-height: 16px;
         border: 1px solid #1E3760;;
         padding: 10px 50px;
         border-radius: 18px;
         color: #1E3760;

         &:hover{
            color: #1E3760;;
            border: 1px solid #1E3760;
            background: #22EEE1;
         }
      }
   }

   & > .info-bandeiras{
      display: flex;
      flex-direction: column;

      & > .titulo-bandeiras{
         color: #08C1C1;
         margin-bottom: 10px;
         font-size: 18px;
      }

      & > .img-bandeiras{
         display: flex;
         flex-direction: row;

         & > img{
            height: 30px;
            margin-right: 20px;
         }
      }

      @media (min-width : 768px) and (max-width : 1366px){

         width:min-content !important;

         & > .img-bandeiras > img{
            height: 18px;
         }
      }

      @media (min-width : 320px) and (max-width : 414px){
         & > .img-bandeiras > img{
            height: 15px;
            margin-right: 10px; 
         }  
      }

   }

   @media(min-width: 320px) and (max-width: 414px){
      width: 80%;
   }

`

export const DivWowBlur = styled.div`

   background: #1E3760;
   height: 1000px;
   background-image: url(${Wvector});
   display: flex;
   justify-content: center;

`

export const BlurryDiv = styled.div`
   
   display: flex;
   flex-direction: row;
   height: 700px;
   width: 1200px;
   justify-content: center;
   align-self: center;
   background: ${props => props.isFirefox?  '#1E3760' :  'none'};
   mix-blend-mode: normal;
   border: 1px solid #1A2944;
   box-sizing: border-box;

   
   @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
    
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
   }


   & > .blurred-text{
      max-width: 800px;
      display: flex;
      align-items: center;

      & > .blue-text{
         display:flex;
         flex-direction: column;
         color: #1E3760;
         font-size: 45px;
         line-height: 53px;
         margin-right: 10px;
         text-align: right;
         align-items: flex-end;
         font-weight:bold;

         & > span{
            background: #08C1C1;
            width: max-content;
         }
      }

      & > .white-text{
         font-size: 18px;
         line-height: 26px;
         font-feature-settings: 'cpsp' on;
         color: #FFFFFF;
         margin-left: 10px;
         text-align: left;
      }


      @media (min-width: 320px) and (max-width: 414px){
         flex-direction: column;
         justify-content: center;

         & > .blue-text{
            text-align: center;
            font-size: 40px;
            margin-right: 0;
         }

         & > .white-text{
            margin: 20px 10px;
         }

      }

   }

`

export const DivQrCode = styled.div`

   background: -moz-linear-gradient(90deg, #22EEE1 60%, #F2F3F8 60%); /* ff3.6+ */
   background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, #F2F3F8), color-stop(60%, #22EEE1)); /* safari4+,chrome */
   background: -webkit-linear-gradient(90deg, #22EEE1 60%, #F2F3F8 60%); /* safari5.1+,chrome10+ */
   background: -o-linear-gradient(90deg, #22EEE1 60%, #F2F3F8 60%); /* opera 11.10+ */
   background: -ms-linear-gradient(90deg, #22EEE1 60%, #F2F3F8 60%); /* ie10+ */
   background: linear-gradient(0deg, #22EEE1 60%, #F2F3F8 60%); /* w3c */ 
   
   display: flex;
   justify-content: center;
   padding-top: 190px;
   padding-bottom: 140px;

   @media(min-width: 320px) and (max-width: 414px){
      flex-direction: column;
   }

`

export const InfoAppDiv = styled.div`

   display: flex;
   flex-direction: column;
   align-items: flex-end;
   justify-content: space-around;
   width: 420px;


   & >  .topo-texto{
      font-size: 48px;
      line-height: 51px;;
      letter-spacing: -1px;
      color: #08C1C1;
      text-align: right;
      display: flex;
      flex-direction: column;
      
      @media (max-width: 1024px){
         font-size: 35px;
      }
   }

   & > .meio-texto{
      color: #1E3760;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: right;

      @media (max-width: 1024px){
         font-size: 18px;
      }
   }

   & > .inf-texto{
      font-size: 18px;
      line-height: 28px;
      text-align: right;
      color: #1E3760;

      @media (max-width: 1024px){
         font-size: 16px;
      }
   }

   @media (max-width: 1024px){
      width: 300px;
   }

   @media(min-width: 320px) and (max-width: 414px){
      align-self: center;

      & > div{
         margin-bottom: 30px;
      }

   }


`

export const SmartphoneDiv = styled.div`

   display: flex;
   margin: 0 100px 0 100px;

   & > img{
      height: 700px;
   }

   @media(max-width : 1366px){
      margin: 0 50px 0 50px;
      & > img{
         height: 600px;
      }
   }

   @media (max-width : 1024px){
      margin: 0;    
      align-items: center;
      & > img{
         height: 500px;
      }  
   } 

   @media (min-width : 768px) and (max-width : 840px){
      align-items: center;
      & > img{
         height: 400px;
      }
   }

   @media(min-width: 320px) and (max-width: 414px){
      justify-content: center;
   }

`

export const AppStoreDiv = styled.div`

   display: flex;
   flex-direction: column;

   & > .wow-block-app{
      align-self: end;

      & > img{
         height: 140px;
      }
      @media (max-width : 1366px){
         & > img{
            height: 120px;
         }
      }
      @media (max-width : 1024px){  
         & > img{
            height: 70px;
         }
      }

      @media (min-width: 320px) and (max-width: 414px){
         display: none;
      }

   }

   & > .texto-app{
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      text-align: center;
      color: #1E3760;
      display: flex;
      flex-direction: column;
      align-self: center;
      margin-top: 130px;
      margin-bottom: 30px;
   
   }

   & > .qrcode-app{
      align-self: center;

      & > img{
         height: 200px;
      
      }

   }


   & > .store-app{
      display:flex;
      flex-direction: row;
      align-self: center;
      margin-top: 50px;

      & > .apple-app{
         margin-right: 30px;
      }

      & > .play-app{
         margin-left: 30px;
      }

      & > div > a > img{
         height: 40px;
      }
   }

`