import React, { useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import { DivCriaConta, InfoCriaConta, BeneficiosDiv,
IpadDiv, Ipad, WowManager, DivCardsHome,
CardsTitulo, DivPosBanner, DivPos, DivInfo, DivWowBlur, BlurryDiv, DivQrCode,
InfoAppDiv, SmartphoneDiv, AppStoreDiv }  from './styles'

import BannerHead from '../../components/bannerHead'
import BannerFoot from '../../components/bannerFoot'
import Footer from '../../components/footer'
import Header from '../../components/header'
import ResponsiveHead from '../../components/ResponsiveHead'
import Carousel from '../../components/carousel'
import AdvHome from '../../components/advHome'

import BannerAppPos from '../../images/imagesHome/BannerAppPos.svg'
// import WowManagerWeb from '../../images/imagesHome/wowManagerIpad.svg'
import WowIpad from '../../images/imagesHome/IpadWow.svg'
import WowBlock from '../../images/wowLightBlueBlock.svg'
import WowPos from '../../images/imagesHome/WowPos.svg'

import FirstLeft from '../../images/imagesHome/leftSide/FirstLeft.svg'
import SecondLeft from '../../images/imagesHome/leftSide/SecondLeft.svg'
import ThirdLeft from '../../images/imagesHome/leftSide/ThirdLeft.svg'
import FourthLeft from '../../images/imagesHome/leftSide/FourthLeft.svg'
import FirstRight from '../../images/imagesHome/rightSide/FirstRight.svg'
import SecondRight from '../../images/imagesHome/rightSide/SecondRight.svg'
import ThirdRight from '../../images/imagesHome/rightSide/ThirdRight.svg'
import FourthRight from '../../images/imagesHome/rightSide/FourthRight.svg'

import LojaAppIcon from '../../images/lojaAppIcon.svg'
import RelatoriosIcon from '../../images/relatoriosIcon.svg'
import CalculadoraIcon from '../../images/calculadoraIcon.svg'
import WifiIcon from '../../images/wifiIcon.svg'
import ChipIcon from '../../images/chipIcon.svg'

import Bandeiras from '../../images/imagesBandeiras/bandeiras.svg'

import Smartphone from '../../images/imagesHome/WowCelular.svg'
import QrCode from '../../images/QRcode.svg'
import AppleStore from '../../images/appleStore.svg'
import PlayStore from '../../images/playStore.svg'


export default () =>{

   const [open, setOpen] = useState(false);

   const checkFirefox = typeof InstallTrigger !== 'undefined';

   const data = useStaticQuery(graphql`
   {
      site {
         siteMetadata {
            WowHome{
               text
            }
         }
      }
   }`)

   const titleHead = [ 'SOLUÇÕES FINANCEIRAS PARA VOCÊ E SUA EMPRESA']

   const descriptionHead = [ 'Mude completamente sua maneira de lidar com dinheiro.' ]
   
   const leftItems = [
      {
         text: "Gera e pague seus boletos.",
         image :{
            src: FirstLeft,
            alt: "First Left"
         }

      },
      {
         text: "Venda à distância, mais opções de captura para o seu negócio.",
         image :{
            src: SecondLeft,
            alt: "Second Left"
         }

      },
      {
         text: "Cartão de crédito pré-pago internacional físico e virtual.",
         image :{
            src: ThirdLeft,
            alt: "Third Left"
         }

      },
      {
         text: "Controle de entrada, saída, faturamento e muito mais.",
         image :{
            src: FourthLeft,
            alt: "Fourth Left"
         }

      }
  ]

  const rightItems = [
      {
         text: "Crédito facilitado para você e sua empresa.",
         image :{
            src: FirstRight,
            alt: "First Right"
         }

      },
      {
         text: "Transferencias para contas Wow e outros bancos.",
         image :{
            src: SecondRight,
            alt: "Second Right"
         }

      },
      {
         text: "Programa de fidelidade personalizado.",
         image :{
            src: ThirdRight,
            alt: "Third Right"
         }

      },
      {
         text: "Conciliação de recebimentos de todas operadoras de cartão.",
         image :{
            src: FourthRight,
            alt: "Fourth Right"
         }

      }
  ]

   
   return(
      <>
         <ResponsiveHead open={open} setOpen={setOpen} />
         
         <Header open={open} setOpen={setOpen} currentPage='Home' />

         <BannerHead image={BannerAppPos} titleHead={titleHead} descriptionHead={descriptionHead} imageName={"Wow solution"} center btnText={'Eu quero!'} urlBtn={'https://www.wowmanager.com.br/signup'}/>

         <DivCardsHome id='servicos'>
            <CardsTitulo>
               <span>SOMOS <b>WOW</b> E OFERECEMOS O </span>
               <span><b>SURPREENDENTE!</b></span>
            </CardsTitulo>
            <Carousel />   
         </DivCardsHome>

         <DivCriaConta id='conta-digital'>
            <div className='wow-block-top'>
               <div>
                  <img src={WowBlock} alt='bloco-wow'></img>
               </div>
            </div>
            <InfoCriaConta>
               <div className='text-left'>
                  <div className='cc-titulo'>
                     <span>SUA</span>
                     <span><b>CONTA DIGITAL</b></span>
                  </div>
                  <div className='cc-subtitulo'>
                     <span>Com a Wow, melhorar sua saúde financeira é surpreendentemente fácil.</span>
                  </div>
                  <div className='cc-texto'>
                     <span>Nascemos com o compromisso de participar e facilitar seu dia a dia.
                     Usamos nossas soluções financeiras inovadoras para oferecer as melhores experiências com soluções transparentes, 
                     intuitivas e rápidas. Uma única plataforma que valoriza seu dinheiro e te faz crescer.</span>
                     <span> <b>Nossa missão é te surpreender!</b></span>
                  </div>
               </div>
               <IpadDiv>
                  <div>
                     <div>
                        <WowManager>
                           {/* <img src={WowManagerWeb} alt='WowManager'></img> */}
                        </WowManager>
                        <Ipad>
                           <img src={WowIpad} alt='WowIpad'></img>
                        </Ipad>
                     </div>
                     {/* <a href='https://www.wowmanager.com.br/signup' rel="noopener noreferrer" target='_blank'>CRIAR MINHA CONTA</a> */}
                     <div className='cria-btn'>
                        <a href='https://www.wowmanager.com.br/signup' rel="noopener noreferrer" target='_blank'>CRIAR MINHA CONTA</a>
                     </div>
                  </div>
               </IpadDiv>
               <BeneficiosDiv>
                  <div>
                     <span>Veja o que você ganha sendo Wow</span>
                     <ul>
                        <li>Maquininha de cartão</li>
                        <li>Plataforma e app intuitivos</li>
                        <li>Antecipação das vendas em 24h</li>
                        <li>Cartão de crédito pré-pago</li>
                        <li>Pagamentos e cobrança via boleto, link ou TED</li>
                        <li>Conciliação de todas suas vendas com gráficos explicativos</li>
                        <li>Crédito facilitado com taxas especiais</li>
                     </ul>
                  </div>
               </BeneficiosDiv>
            </InfoCriaConta>
            <div className='wow-block-bot'>
               <div>
                  <img src={WowBlock} alt='bloco-wow'></img>
               </div>
            </div>
         </DivCriaConta>

         <AdvHome leftItems={leftItems} rightItems={rightItems} />
         
         <DivPosBanner>
            <DivPos>
               <div className="lista-pos">
                  <ul>
                     <li>Loja de aplicações<img src={LojaAppIcon} alt='LojaApp'/></li>
                     <li>Relatórios<img src={RelatoriosIcon} alt='Relatorios'/></li>
                     <li>Calculadora<img src={CalculadoraIcon} alt='Calculadora'/></li>
                     <li>Conexão Wifi<img src={WifiIcon} alt='Wireless'/></li>
                     <li>Conexão GRPS<img src={ChipIcon} alt='Chip'/></li>
                  </ul>
               </div>
               <div className='image-pos'>
                  <img src={WowPos} alt='WowPos'/>
               </div>
            </DivPos>

            <DivInfo>
               <div className='info-titulo'>
                  <span>RECEBA SUAS</span>
                  <span><b>VENDAS EM 24 HORAS</b></span>
               </div>
               <div className='info-sub'>
                  <span><b>Uma máquina que é muito mais do que uma</b></span>
                  <span><b> máquina de cartão.</b></span>
               </div>
               <div className='info-texto'>
                  <span>Tenha uma plataforma de gestão completa. 
                  Receba insights sobre seu negócio e use gráficos intuitivos. 
                  Utilize nossos comparativos e conheça mais sobre seu fluxo financeiro e sobre os hábitos do seu cliente. 
                  Pode acreditar... oferecemos uma máquina que é muito mais do que uma máquina de cartão.</span> 
                  <span><b>Ela é surpreendente!</b></span>
               </div>
               <div className='info-btn'>
                  <Link to='/pay'>SAIBA MAIS</Link>
               </div>
               <div className='info-bandeiras'>
                  <div className='titulo-bandeiras'>
                     <span><b>Bandeiras aceitas</b></span>
                  </div>
                  <div className='img-bandeiras'>
                     <img src={Bandeiras} alt='Bandeiras' />
                     {/* <img src={MasterCred} alt='MasterCred' /> 
                     <img src={MasterDeb} alt='MasterDeb' /> 
                     <img src={Visa} alt='Visa' /> 
                     <img src={VisaElec} alt='VisaElectron' /> 
                     <img src={Elo} alt='Elo' /> 
                     <img src={Amex} alt='Amex' /> 
                     <img src={Hiper} alt='Hipercard' />  */}
                  </div>
               </div>
            </DivInfo>
         </DivPosBanner>

         <DivWowBlur id='ser-wow'>
            <BlurryDiv isFirefox={checkFirefox}>
               <div className='blurred-text'>
                  <div className='blue-text'>
                     <span>SER WOW É...</span>
                     <span>DESCOMPLICADO.</span>
                     <span>SEGURO.</span>
                     <span>COMPLETO.</span>
                  </div>
                  <div className='white-text'>
                     <span>
                        Nascemos para estar um passo à frente, para evoluir e te levar conosco. 
                        Cabemos no seu bolso, na sua vida e, mesmo assim, mudamos tudo. 
                        Você é nossa prioridade e juntos criamos soluções para gestão financeira de uma forma incrível e tecnológica, 
                        para que você resolva tudo a partir de uma plataforma digital rápida, descomplicada, completa e segura!
                     </span>
                  </div>
               </div>
            </BlurryDiv>
         </DivWowBlur>

         <DivQrCode>
            
            <InfoAppDiv>
               <div className='topo-texto'>
                  <span>BAIXE O <b>APP WOW</b></span>
                  <span>AGORA MESMO!</span>
               </div>
               <div className='meio-texto'>
                  <span>Estamos com você! Leve todas as soluções e vantagens da Wow no seu bolso e leve sua gestão financeira para qualquer lugar do mundo!</span>
               </div>
               <div className='inf-texto' >
                  <span>
                     Baixe o APP da Wow e tenha todos os alertas, insights e facilidades para acessar a qualquer hora do dia! 
                     Consiga realizar transferências, pagar e gerar boletos, adicionar saldo em seus cartões internacionais 
                     e acompanhe suas vendas mesmo que esteja longe!
                  </span>
               </div>
            </InfoAppDiv>

            <SmartphoneDiv>
               <img src={Smartphone} alt='Smartphone' />
            </SmartphoneDiv>

            <AppStoreDiv id='app-wow'>
               <div className='wow-block-app'>
                  <img src={WowBlock} alt='w-block'/>
               </div>
               <div className='texto-app'>
                  <span>Aponte para</span>
                  <span>o QR Code para</span>
                  <span>baixar</span>
               </div>
               <div className='qrcode-app'>
                  <img src={QrCode} alt='QrCode' />
               </div>
               <div className='store-app'>
                  <div className='apple-app'>
                     <a href='https://apps.apple.com/br/app/wow-solution/id1441435788' rel='noopener noreferrer' target='_blank'>
                        <img src={AppleStore} alt='AppleStote' />
                     </a>
                  </div>
                  <div className='play-app'>
                     <a href='https://play.google.com/store/apps/details?id=com.appwownew&hl=pt_BR' rel='noopener noreferrer' target='_blank'>
                        <img src={PlayStore} alt='PlayStore' />
                     </a>
                  </div>
               </div>
            </AppStoreDiv>

         </DivQrCode>

         
         <BannerFoot>
            {data.site.siteMetadata.WowHome.text}
         </BannerFoot>
         

         <Footer />
      </>
   )


}